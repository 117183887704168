import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Mike = () => {
  const data = useStaticQuery(graphql`
    query {
      mike: file(relativePath: { eq: "Mike.png" }) {
        childImageSharp {
          fluid(maxWidth: 512, maxHeight: 512) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="photo-container mx-auto">
      <Img fluid={data.mike.childImageSharp.fluid} className="rounded-circle" />
    </div>
  )
}

export default Mike